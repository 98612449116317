import { Group } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Theme,
  useMediaQuery,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import Image from "next/legacy/image";
import Link from "next/link";

import useGroup from "hooks/useGroup";
import useMe from "hooks/useMe";
import { FeedActivity, StreamUser } from "lib/motorcade/types";

import ActivityCardHeader from "../base/ActivityCardHeader";

type Props = {
  activity: FeedActivity & {
    verb: "join";
  };
  actors?: StreamUser[];
};

export default function JoinCard({ activity, actors }: Props) {
  const { id: meId } = useMe();
  const {
    id,
    created_at,
    data: { description, name, slug, image },
  } = activity.object || {};

  const { group } = useGroup({ groupId: id });
  const totalMembers = group?.totalFollowers;

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"));

  const filteredActors = actors?.filter((actor) => actor.id !== meId);

  return (
    <Card>
      <ActivityCardHeader
        hideAvatar
        hideContextMenu
        hideIsEdited
        actionText="joined"
        activity={activity}
        actors={filteredActors}
      />

      <Box px={3}>
        <Divider />
      </Box>

      <CardContent sx={{ px: 3 }}>
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection={isXs ? "column" : "row"}
          gap={2}
          width="100%"
        >
          {image && (
            <Box
              flex={1}
              height={{ xs: "185px", sm: "130px" }}
              maxWidth={{ xs: "100%", sm: "225px" }}
              width="100%"
            >
              <Link
                className="pendo_web-joinedgroup-gotogroup"
                href={`/groups/${slug}`}
                style={{
                  display: "block",
                  position: "relative",
                  minHeight: "130px",
                  height: isXs ? "185px" : "130px",
                }}
              >
                <Image
                  alt={name}
                  height="185px"
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  src={image}
                  style={{ borderRadius: "6px" }}
                  width="550px"
                />
              </Link>
            </Box>
          )}

          <Box
            alignItems="flex-start"
            display="flex"
            flex={1}
            flexDirection="column"
            gap={1}
            minWidth={0}
            width="100%"
          >
            <Link href={`/groups/${slug}`}>
              <Typography
                className="pendo_web-joinedgroup-gotogroup"
                color="textPrimary"
                variant="h3"
              >
                {name}
              </Typography>
            </Link>

            <Typography variant="body2">{description}</Typography>

            <Box
              alignItems="center"
              display="flex"
              flexWrap="wrap"
              gap={1}
              width="100%"
            >
              {totalMembers > 0 && (
                <>
                  <Group />
                  <Link
                    passHref
                    className="pendo_web-joinedgroup-groupmembers"
                    href={`/groups/${slug}?tab=members`}
                  >
                    <Typography noWrap variant="body2">
                      {totalMembers} member{totalMembers > 1 ? "s" : ""}
                    </Typography>
                  </Link>
                </>
              )}

              {totalMembers > 0 && created_at && (
                <div
                  style={{
                    width: 3,
                    height: 3,
                    borderRadius: "50%",
                    backgroundColor: "#333",
                  }}
                />
              )}

              {created_at && (
                <Typography noWrap variant="body2">
                  Created {format(new Date(created_at), "MMMM d, yyyy")}
                </Typography>
              )}
            </Box>
          </Box>
          <Link legacyBehavior passHref href={`/groups/${slug}`}>
            <Button
              className="pendo_web-joinedgroup-gotogroup"
              sx={{ flexShrink: 0 }}
              variant="contained"
            >
              View Group
            </Button>
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
}
